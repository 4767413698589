<template>
  <div class="logistics">
    <div class="w logistics-query">
      <div class="title">
        {{this.$t('selectQuery')}}
      </div>
      <div class="search">
        <span class="query">{{this.$t('LogisticsQuery')}}</span>
        <input type="text" v-model="query.orderCode" :placeholder="this.$t('expressQuery')"/>
        <button class="sousuo" @click="search">{{this.$t('query')}}</button>
      </div>
      <div class="tips">
        {{this.$t('introduce')}}
      </div>
    </div>
    <div class="w logistics-result" v-if="result">
    <el-timeline>
    <el-timeline-item
      v-for="(item, index) in queryResult"
      :key="index"
      :type="item.type"
      :size="item.size"
      :timestamp="item.time">
      {{item.context}}
    </el-timeline-item>
  </el-timeline>
    </div>
  </div>
</template>

<script>
import { expressQuery } from './model'
import { showLayer } from "@/utils/com";
export default {
  data(){
    return {
     query:{
        orderCode:''
     },
     queryResult:'',
     result:false,
     noResult:false
    }
  },
  methods:{
    search(){
      if(this.query.orderCode !== ''){
         expressQuery(this.query).then(res =>{
           if(res.data){
             this.queryResult = res.data;
             this.result = true;
           }
        })
      }else {
        showLayer("toast", "请输入订单编号或者物流单号！");
      }
    }
  }
};
</script>

<style>
/* .logistics {
  margin: 0.2rem 0;
}
.search {
  position: relative;
  text-align: center;
  margin-top: 0.2rem;
}
.logistics-query .query {
  position: absolute;
  left: 0.7rem;
  display: inline-block;
  width: 0.8rem;
  height: 0.3rem;
  line-height: 0.3rem;
  border: 1px solid #ccc;
  border-right: none;
  font-size: 16px;
  font-weight: 700;
}

.sousuo {
  position: absolute;
  right: 1.1rem;
  display: inline-block;
  width: 0.4rem;
  height: 0.3rem;
  line-height: 0.3rem;
  border: 1px solid #ccc;
  border-left: none;
  font-size: 16px;
  font-weight: 700;
  background: #32b16c;
  color: #fff;
  cursor: pointer;
}
.logistics-query input {
  display: inline-block;
  width: 3rem;
  height: 0.3rem;
  border: 1px solid #ccc;
  outline: none;
}
.logistics-query .title {
  font-size: 20px;
  font-weight: 700;
  width: 100%;
  height: 0.2rem;
  line-height: 0.2rem;
  text-align: center;
}
.tips {
    text-align: center;
    width: 100%;
    height: .3rem;
    line-height: .3rem;
    font-size: 16px;
}
.search input {
  font-size: 16px;
}
.logistics-result {
  width: 50%;
  margin: 0 auto;
  padding: 0.1rem;
  border: 1px solid #ccc;
}
.no-result {
  position: relative;
  width: 50%;
  height: 1rem;
  border: 1px solid #ccc;
}
.tip-info {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50% ,-50%);
  width: 100%;
  text-align: center;
  font-size: .1rem;
  height: .2rem;
  line-height: .2rem;
} */
</style>
